.container-fornoinfo {
  display: flex;
  flex-direction: row;
  width: 500px;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin: 20px 0;
}
.container-fuorinfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
}
.container-ing {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px 0;
}
.ingr {
  display: flex;
  flex-direction: row;
  width: 49%;
  height: auto;
  margin: 2px auto;
  padding: 5px 10px;
  box-sizing: border-box;
}
.field textarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 100px;
  resize: none;
}
