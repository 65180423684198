.baseinfo-container {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: auto;
  margin: 10px 0;
}
.field {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}
.field__label-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
}
.pizza-btn-container {
  display: flex;
  flex-direction: row;
  width: 500px;
  height: auto;
  align-items: center;
  justify-content: space-between;
}
.pizza-btn-container .btn{
    margin: 0;
}
.container-fuorinfo{
  max-width: 500px;
}