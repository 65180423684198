* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.App {
  overflow: hidden;
  height: 100vh;
  width: 100vw;

 

}
