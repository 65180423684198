
  .prodotti-carrello{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
  
  }
    
   
    
  