@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");
.container-table{
  display: flex;
  flex-direction: column;
  width: 100%;
}
section {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  display: table;
  width: 100%;
  padding: 0;
  margin: auto;
  box-sizing: border-box;
}
.table {
  display: table;
  width: 100%;
  padding: 0 10px;
  margin: auto;
  box-sizing: border-box;
}
.header {
  display: table-row;
  width: 100%;
  height: auto;
  min-height: 30px;
  border-style: solid;
  border-width: 10px;
  background-color: rgb(45, 88, 140);
  color: #ffffff;
  min-height: 25px;
}
.col {
}

section > * {
  display: table-row;
}

section .col {
  display: table-cell;
  height: auto;
  padding: 5px 10px;
  border-bottom: solid;
  border-width: 0.1rem;
  border-color: rgb(182, 182, 182);
}
.row {
  display: table-row;
  width: 100%;
  height: auto;
}
.int {
  font-weight: bold;
  text-transform: uppercase;
}
.nume-page{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.pagine{
  margin: 10px;
  font-size: 1,3em;
}
.red{
  color: azure;
  
}
