.nav {
  display: flex;
  flex-direction: column;
  width: 220px;
  position: fixed;
  background-color: #ffffff;
  top: 0px;
  border-right: 1px solid #c4c4c4;
  font-weight: bold;
  overflow: auto;
  bottom: 0;
  z-index: 9000;
}
.nav h2{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
}
.content-nav {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
  height: auto;
}
.content-nav a {
  line-height: 1.5em;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: rgba(#fff, 0.35);
  color: rgb(84, 94, 111);
  padding: 10px 10px;
  font-variant: small-caps;
}
.content-nav.active a {
  color: white;
}
.content-nav:not(.active)::after {
  opacity: 0.2;
}
.content-nav:not(.active):hover a {
  color: rgba(#fff, 0.75);
}
.content-nav::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.2em;
  background: rgb(155, 155, 155);
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to right, #aa9ad3, #b27f9f);
}
.line {
  border-bottom: 1px solid #9e9e9e;
}
.logout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 5px;
  box-sizing: border-box;
  color: #7600dc;
}
