.container-topbar {
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background-color: #246cbe;
  box-sizing: border-box;
  left: 221px;
  right: 0;
  top: 0;
  height: 45px;
  z-index: 10000;
}
.gruppo_icone {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  align-items: center;
  justify-content: flex-start;
}
.menu-topbar {
  display: flex;
  flex-direction: row;
  font-size: 0.9em;
  margin: auto 5px;
  padding: 5px;
  align-content: center;
  justify-content: center;
  color: #ffffff;
  text-decoration: none;
}
.menu-topbar label {
  display: flex;
  flex-direction: row;
  width: auto;
  height: 25px;
  align-items: center;
  justify-content: center;
}
