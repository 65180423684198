@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");
.container-input-ordine {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 40vw;
  height: 40vh;
  background-color: antiquewhite;
  padding: 20px;
}

.contenuto--input-ordine {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.numero {
  font-size: 1.5em;
}
.ordini {
  font-size: 2em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.input-ordini {
  padding: 10px;
}

/*
=====
HELPERS
=====
*/

.ha-screen-reader {
  width: var(--ha-screen-reader-width, 1px);
  height: var(--ha-screen-reader-height, 1px);
  padding: var(--ha-screen-reader-padding, 0);
  border: var(--ha-screen-reader-border, none);

  position: var(--ha-screen-reader-position, absolute);
  clip: var(--ha-screen-reader-clip, rect(1px, 1px, 1px, 1px));
  overflow: var(--ha-screen-reader-overflow, hidden);
}

/*
  =====
  RESET STYLES
  =====
  */

.field__input {
  --uiFieldPlaceholderColor: var(--fieldPlaceholderColor, #767676);

  background-color: transparent;
  border-radius: 0;
  border: none;

  -webkit-appearance: none;
  -moz-appearance: none;

  font-family: inherit;
  font-size: inherit;
}

.field__input:focus::-webkit-input-placeholder {
  color: var(--uiFieldPlaceholderColor);
}

.field__input:focus::-moz-placeholder {
  color: var(--uiFieldPlaceholderColor);
}

/*
  =====
  CORE STYLES
  =====
  */

.field {
  --uiFieldBorderWidth: var(--fieldBorderWidth, 2px);
  --uiFieldPaddingRight: var(--fieldPaddingRight, 1rem);
  --uiFieldPaddingLeft: var(--fieldPaddingLeft, 1rem);
  --uiFieldBorderColorActive: var(
    --fieldBorderColorActive,
    rgba(22, 22, 22, 1)
  );

  display: var(--fieldDisplay, inline-flex);
  position: relative;
  font-size: var(--fieldFontSize, 1rem);
  transition: all ease-in-out 0.4s;
}

.field__input {
  box-sizing: border-box;
  width: var(--fieldWidth, 100%);
  height: var(--fieldHeight, 3rem);
  padding: var(--fieldPaddingTop, 1.25rem) var(--uiFieldPaddingRight)
    var(--fieldPaddingBottom, 0.5rem) var(--uiFieldPaddingLeft);
  border-bottom: var(--uiFieldBorderWidth) solid
    var(--fieldBorderColor, rgba(0, 0, 0, 0.25));
}

.field__input:focus {
  outline: none;
}

.field__input::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.field__input::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.field__input:focus::-webkit-input-placeholder {
  opacity: 1;
  transition-delay: 0.2s;
}

.field__input:focus::-moz-placeholder {
  opacity: 1;
  transition-delay: 0.2s;
}

.field__label-wrap {
  box-sizing: border-box;
  pointer-events: none;
  cursor: text;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.field__label-wrap::after {
  content: "";
  box-sizing: border-box;
  width: 100%;
  height: 0;
  opacity: 0;

  position: absolute;
  bottom: 0;
  left: 0;
}

.field__input:focus ~ .field__label-wrap::after {
  opacity: 1;
}

.field__label {
  position: absolute;
  left: var(--uiFieldPaddingLeft);
  top: calc(50% - 0.5em);

  line-height: 1;
  font-size: var(--fieldHintFontSize, inherit);

  transition: top 0.2s cubic-bezier(0.9, -0.15, 0.1, 1.15),
    opacity 0.2s ease-out, font-size 0.2s ease-out;
}

.field__input:focus ~ .field__label-wrap .field__label,
.field__input:not(:placeholder-shown) ~ .field__label-wrap .field__label {
  --fieldHintFontSize: var(--fieldHintFontSizeFocused, 0.75rem);

  top: var(--fieldHintTopHover, 0.25rem);
}

/* 
  effect 1
  */

.field_v1 .field__label-wrap::after {
  border-bottom: var(--uiFieldBorderWidth) solid var(--uiFieldBorderColorActive);
  transition: opacity 0.2s ease-out;
}

/* 
  effect 2
  */

.field_v2 .field__label-wrap {
  overflow: hidden;
}

.field_v2 .field__label-wrap::after {
  border-bottom: var(--uiFieldBorderWidth) solid var(--uiFieldBorderColorActive);
  transform: translate3d(-105%, 0, 0);
  transition: transform 0.285s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
}

.field_v2 .field__input:focus ~ .field__label-wrap::after {
  transform: translate3d(0, 0, 0);
  transition-delay: 0;
}

/*
  effect 3
  */

.field_v3 .field__label-wrap::after {
  border: var(--uiFieldBorderWidth) solid var(--uiFieldBorderColorActive);
  transition: height 0.2s ease-out, opacity 0.2s ease-out;
}

.field_v3 .field__input:focus ~ .field__label-wrap::after {
  height: 100%;
}

/*
  =====
  LEVEL 4. SETTINGS
  =====
  */

.field {
  --fieldBorderColor: #3b70a6;
  --fieldBorderColorActive: #61c168;
}

/*
  =====
  DEMO
  =====
  */

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Open Sans,
    Ubuntu, Fira Sans, Helvetica Neue, sans-serif;
  margin: 0;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page {
  box-sizing: border-box;
  width: 100%;
  max-width: 480px;
  margin: auto;
  padding: 1rem;

  display: grid;
  grid-gap: 30px;
}

.linktr {
  order: -1;
  padding: 1.75rem;
  text-align: center;
}

.linktr__goal {
  background-color: rgb(209, 246, 255);
  color: rgb(8, 49, 112);
  box-shadow: rgb(8 49 112 / 24%) 0px 2px 8px 0px;
  border-radius: 2rem;
  padding: 0.5rem 1.25rem;
}

@media (min-width: 1024px) {
  .linktr {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }
}

.r-link {
  --uirLinkDisplay: var(--rLinkDisplay, inline-flex);
  --uirLinkTextColor: var(--rLinkTextColor);
  --uirLinkTextDecoration: var(--rLinkTextDecoration, none);

  display: var(--uirLinkDisplay) !important;
  color: var(--uirLinkTextColor) !important;
  text-decoration: var(--uirLinkTextDecoration) !important;
}

.btn {
  padding: 0.5em 2.1em;
  border-radius: 1rem;
  border: 1px solid #172d69;
  color: #ffffff;
  background-color: #fbdedb;
  display: inline-block;
  background: #39879c;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 0.85em;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
  position: relative;
}
.btn:hover,
.btn:focus {
  opacity: 0.8;
}
.btn:active {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}
.btn.block {
  display: block !important;
}
.btn.circular {
  border-radius: 50em !important;
}

/* Colours */

.purple {
  background-color: #172d69;
}
.red {
  background-color: #7e0101;
  width: 31px;
  height: 30px;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.neutro{
  background-color: #f8f8f8;
  align-items: center;
  justify-content: center;
  padding: .5em 2em;
  color: #373737;
  margin: 0 5px;
}