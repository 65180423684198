.container-home {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  height: auto;
  min-height: 90vh;
  margin: auto 0;
  left: 120px;
  width: 50vw;
}

.contnuto {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.info {
  width: 100%;
}
.container-home .ingr {
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
}
