.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}
.logo_in_form{
  width: 200px;
  height: 200px;
  object-fit: contain;
  margin: 0 auto;
}
