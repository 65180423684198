.container-portate {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.container-ordini {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
}
.container-ordini .apritavolo {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  padding: 10px;
  margin: 0 auto;
  margin-top: 0;
}
.container-ordini{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin: 5px 0;
}