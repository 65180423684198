.select {
  display: flex;
  width: 300px;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.select select {
  padding: 5px;
  box-sizing: border-box;
  font-size: 16px;
}
.select button {
  display: flex;
  flex-direction: row;
  width: 40%;
  height: auto;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #ffffff;
  background-color: rgb(#2a60bd, #2d589e, #2a60bd);
  margin-top: 10px;
}