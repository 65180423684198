.container-pizzastepper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 40px;
  width: 100%;
}
.progressbar {
  width: 400px;
  height: 10px;
  background-color: white;
  margin-bottom: 20px;
}

.progressbar div {
  width: 33.3%;
  height: 100%;
  background-color: rgb(98, 0, 255);
}
.pizza-btn-container{
    display: flex;
    flex-direction: row;
    padding: 20px;
    margin: 10px;
}
.btn{
    margin: 10px;
}
.titolo-page{
    font-size: 2em;
}
.ingredienti{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  
}