.conteiner-antipasti {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.piatti {
  display: flex;
  flex-direction: column;
}
.piatti div {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  margin: 10px 0;
  align-items: center;
  justify-content: center;
}
.piatti div select {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 10px;
}
